<template>
  <ListPhotosPage :datatable-config="this.datatableConfig" :get-photos-function="getPhotosFunction" export-route-name="export-app-photos"/>
</template>

<script>

import PhotoService from '@/modules/Apps/Services/PhotoService'
import ListPhotosPage from '@/modules/Shared/Components/photos/ListPhotosPage'

export default {
  name: 'app-photos',
  components: {
    ListPhotosPage
  },
  data () {
    return {
      datatableConfig: [
        { 'type': 'image', 'field': 'path', head: this.$t("apps.photos.picture"), 'searchable': false, 'sorteable': false },
        { 'type': 'text', 'field': 'username', head: this.$t("apps.photos.user"), 'searchable': true, 'sorteable': false },
        { 'type': 'text', 'field': 'date', head: this.$t("apps.photos.date"), 'searchable': true, 'sorteable': false },
        { 'type': 'text', 'field': 'city', head: this.$t("apps.photos.city"), 'searchable': true, 'sorteable': false },
        { 'type': 'text', 'field': 'country', head: this.$t("apps.photos.country"), 'searchable': true, 'sorteable': false },
        { 'type': 'text', 'field': 'resortname', head: this.$t("apps.photos.resort"), 'searchable': true, 'sorteable': false },
        { 'type': 'actions', 'field': 'actions', head: this.$t("apps.photos.actions"), 'searchable': false, 'sorteable': false }
      ],
      getPhotosFunction: PhotoService.getPhotos
    }
  }
}
</script>
