import axios from '@/axios.js'

export default {
    getPhotos: async (appUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/${appUuid}/photos`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    exportPhotos: async (appUuid, parameters) => {
        try {
            const response = await axios.editorapi.get(`apps/v3/export/${appUuid}/photos`, { params: parameters })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
}