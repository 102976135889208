var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ListPhotosPage", {
    attrs: {
      "datatable-config": this.datatableConfig,
      "get-photos-function": _vm.getPhotosFunction,
      "export-route-name": "export-app-photos"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }